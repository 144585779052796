import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormZipcode = lazy(
  () => import("./form-zipcode-CTjvTqRW.js").then((module) => ({
    default: module.FormZipcode
  }))
);
function FormZipcode({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormZipcode, { ...props });
}
const useFormZipcodeContent = () => {
  return useMemo(
    () => ({
      formZipcode: {
        component: FormZipcode
      }
    }),
    []
  );
};
export {
  LazyFormZipcode as FormZipcode,
  useFormZipcodeContent
};
